<template>
  <section class="relative">
    <img
      src="../assets/header.jpg"
      alt="A deep and immersive urban garden with a lush variety of plants"
      class="mx-auto"
    />
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-shadow text-center"
    >
      <h1 class="text-4xl md:text-5xl font-normal py-4 w-full">
        {{ heading }}
      </h1>
      <h2 class="text-3xl md:text-4xl font-light lowercase w-screen">
        {{ subheading }}
      </h2>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    heading: String,
    subheading: String,
  },
}
</script>
