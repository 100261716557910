<template>
  <div
    id="top"
    class="relative z-10 mx-auto text-normal lg:text-2xl uppercase font-semibold text-white text-shadow"
  >
    <div class="absolute top-2 left-1/2 transform -translate-x-1/2">
      <router-link to="#services" class="pr-6">Services</router-link>
      <router-link to="#about" class="pr-6">About</router-link>
      <router-link to="#contact">Contact</router-link>
    </div>
  </div>
  <router-view />
</template>
