<template>
  <div class="home">
    <Hero
      heading="Jane MacLeish"
      subheading="creates landscapes that are uniquely suited to their&nbsp;locations"
    />
    <Quotation
      quote="&ldquo;All gardening is landscape painting&rdquo;"
      author="— William Kent"
    />
    <section id="services" class="w-screen md:w-10/12 mx-auto pb-4">
      <h2 class="uppercase text-center text-3xl md:text-6xl pt-8 pb-2">
        Services
      </h2>
      <hr class="border-green-500 border-2 w-12 mx-auto mb-2 lg:mb-8" />

      <h3
        class="w-11/12 text-xl md:text-2xl font-light pt-8 pb-1 md:pb-4 pl-2 md:pl-0"
      >
        Design Consultation &amp; Referrals
      </h3>
      <img
        src="../assets/home_design_consult.jpg"
        alt="Urban garden with pond"
        class="border-gray-800 border-2 w-full"
      />
      <p class="font-serif text-lg pt-2 tracking-wide px-3 md:px-0">
        Starting with an on-site visit to your garden to assess it and
        understand your goals, Jane can provide you with suggestions and
        recommendations for a successful redesign. This service includes
        referrals to a robust network of garden engineers and craftsmen who
        understand her approach and can realize her vision. Each garden project
        is custom-tailored to the needs and budget of the client. No garden is
        is too small or inconsequential.
      </p>

      <h3
        class="w-11/12 text-xl md:text-2xl font-light pt-8 pb-1 md:pb-4 pl-2 md:pl-0"
      >
        Garden Planning
      </h3>
      <img
        src="../assets/home_garden_planning.jpg"
        alt="Two chairs and a table on a landscaped terrace"
        class="border-gray-800 border-2 w-full"
      />
      <p class="font-serif text-lg pt-2 tracking-wide px-3 md:px-0">
        Jane is currently focusing on exquisite garden projects for individual
        clients providing full-service garden design from initial consultation,
        project budgeting and timeline through installation.
      </p>

      <h3
        class="w-11/12 text-xl md:text-2xl font-light pt-8 pb-1 md:pb-4 pl-2 md:pl-0"
      >
        Garden Rejuvenation &amp; Stewardship
      </h3>
      <img
        src="../assets/home_stewardship.jpg"
        alt="Log cabin with a fenced garden in a rural setting"
        class="border-gray-800 border-2 w-full"
      />
      <p class="font-serif text-lg pt-2 tracking-wide px-3 md:px-0">
        Simple changes can make a significant impact. Sometimes all a garden
        needs is the strategic replacement of plants that have lost their oomph
        and some creative rethinking of areas that are not flourishing to their
        full potential. Jane also offers the option of working with you on an
        ongoing basis to manage the development of your garden to preserve or
        evolve the original vision, taking into account ever-changing
        environmental factors, personal preferences, and new ideas.
      </p>

      <h3
        class="w-11/12 text-xl md:text-2xl font-light pt-8 pb-1 md:pb-4 pl-2 md:pl-0"
      >
        Speaking Engagements
      </h3>
      <p class="font-serif text-lg tracking-wide px-3 md:px-0">
        Growing up in the English countryside had a deep influence on Jane’s
        garden design sensibility. Her approach to garden landscapes can help
        others appreciate them with new eyes. Jane is available to speak at
        lectures, symposiums and other engagements in the Washington, D.C.,
        metropolitan area to share her passion, enthusiasm and experience as a
        master gardener with audiences old and new.
      </p>
    </section>
    <Quotation
      quote="&ldquo;Deep within each one of us lies a garden&rdquo;"
      author="— Julie Moir Messervy"
    />
    <section id="about" class="w-10/12 mx-auto pb-4 flow-root">
      <h2 class="uppercase text-center text-3xl md:text-6xl pt-8 pb-0 md:pb-2">
        About Jane
      </h2>
      <hr class="border-green-500 border-2 w-12 mx-auto mb-2 lg:mb-8" />
      <div class="py-8">
        <img
          class="mx-auto mb-4 lg:float-left lg:mr-4 lg:mb-2 border-gray-800 border-2"
          src="../assets/jane-macleish.jpg"
          alt="Photo of Jane MacLeish"
        />
        <div class="font-serif text-lg">
          <p class="mb-2">
            Jane MacLeish is one of the leading garden makers in the Nation's
            Capital Region.
          </p>
          <p class="mb-2">
            She brings a profound knowledge of English gardens, 40 years of
            experience with American gardens and a keen sensibility of the
            relationship of a garden and its owner to her work.
          </p>
          <p class="mb-2">
            Through her company, <em>Jane Krumbhaar MacLeish Landscapes</em>,
            she has designed and constructed some of the most well-known and
            highly celebrated gardens in the area including Ondine — a new
            garden at Dumbarton Oaks, Historic Old Town Lee Mansion in
            Alexandria, and two gardens at One Observatory Circle — the Vice
            President's official residence.
          </p>
          <p class="mb-2">
            Currently, Jane MacLeish is happy to work with small city gardens in
            addition to on-site consultations and recommendations with garden
            owners. She is well known for her ability to restore old gardens and
            collaborates with a broad network of garden artisans and craftsmen.
          </p>
          <p class="mb-2">
            Jane is a co-founder of the
            <a href="https://dopark.org/" class="text-green-500">
              Dumbarton Oaks Park Conservancy</a
            >, launched in 2010 to restore what was originally an important part
            the Dumbarton Oaks Garden, one of the ten greatest garden landscape
            designs in the United States.
          </p>
          <p class="mb-2">
            She currently serves as a member of the Washington DC Commission on
            the Arts and Humanities'
            <a
              href="https://dcarts.dc.gov/service/public-art"
              class="text-green-500"
              >DC Creates! Public Art Program</a
            >, established to create a dynamic, vibrant, nurturing community by
            enabling artists to create art in public venues in the District.
          </p>
          <p class="mb-2">
            Jane MacLeish is available for lectures and other speaking
            engagements in and around Washington, D.C.
          </p>
        </div>
      </div>
    </section>
    <section id="contact" class="relative py-4">
      <div class="full-width h-32 md:h-48 bg-green-600">
        <div
          class="absolute w-11/12 md:w-6/12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
        >
          <h2 class="uppercase text-center text-4xl">Contact Jane</h2>
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="block mx-auto h-12 w-12"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
          </p>
          <p class="text-lg font-thin text-center">
            <a href="mailto:contact@janemacleish.com">
              contact@janemacleish.com
            </a>
          </p>
        </div>
      </div>
    </section>
    <footer>
      <p class="text-center text-black pt-2 pb-1">
        All content ©2012 - {{ new Date().getFullYear() }} Jane Krumbhaar
        MacLeish Landscapes
      </p>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import Quotation from '@/components/Quotation.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    Quotation,
  },
}
</script>

<style scoped>
.home {
  scroll-behavior: smooth;
}
</style>
