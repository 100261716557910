<template>
  <section class="relative">
    <div class="full-width h-24 md:h-32 bg-green-600">
      <div
        class="absolute w-11/12 md:w-6/12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center"
      >
        <p class="text-xl lg:text-2xl italic font-normal">{{ quote }}</p>
        <p class="text-base lg:text-lg font-normal text-center">{{ author }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Quotation',
  props: {
    quote: String,
    author: String,
  },
}
</script>
